/** @jsx jsx */
import { Link } from "gatsby"
import React, { useState } from "react"
import {
  Box,
  Button,
  Card,
  Checkbox,
  Heading,
  Input,
  jsx,
  Label,
  Link as TLink,
  Styled,
  Textarea,
} from "theme-ui"

var _ = require("lodash")

const ContactForm = props => {
  const [formState, setFormState] = useState({
    username: "",
    email: "",
    notRequestingHelp: false,
    message: "",
    hasError: false,
    isSubmitted: false,
  })

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    setFormState({
      ...formState,
      hasError: false,
      [e.target.name]: e.target.value,
    })
  }

  const handleCheckboxChange = e => {
    setFormState({
      ...formState,
      hasErorr: false,
      notRequestingHelp: e.target.checked,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (
      !formState.notRequestingHelp ||
      _.isEmpty(formState.username) ||
      _.isEmpty(formState.email) ||
      _.isEmpty(formState.message)
    ) {
      setFormState({
        hasError: true,
      })
      return
    }
    await fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formState }),
    })
    setFormState({
      isSubmitted: true,
    })
  }

  if (formState.isSubmitted) {
    return (
      <Card py={4} my={4} sx={{ textAlign: "center" }} bg={"muted"}>
        <Heading>I got your message. Thanks for contacting me!</Heading>
      </Card>
    )
  }

  return (
    <React.Fragment>
      <Heading variant="styles.h2" mb={4}>
        Contact Me
      </Heading>

      <Styled.blockquote>
        I <strong>WILL NOT</strong> help/tutor you on your project without using
        my consulting services. If you need help - visit the{" "}
        <TLink as={Link} to={"/consulting/"}>
          Consulting Page
        </TLink>
      </Styled.blockquote>
      <Box
        as="form"
        onSubmit={handleSubmit}
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        {formState.hasError && (
          <Styled.p sx={{ color: "error" }}>
            Error! You must enter all fields.
          </Styled.p>
        )}
        <input type="hidden" name="form-name" value="contact" />
        <Label htmlFor="username">Your Name*</Label>
        <Input name="username" mb={3} onChange={handleChange} />
        <Label htmlFor="email">Your Email*</Label>
        <Input type="email" name="email" mb={3} onChange={handleChange} />
        <Box>
          <Label mb={3}>
            <Checkbox
              onChange={handleCheckboxChange}
              checked={formState.notRequestingHelp}
            />
            I am not asking for help on my project/problem*
          </Label>
        </Box>
        <Label htmlFor="message">Your Message*</Label>
        <Textarea name="message" rows="6" mb={3} onChange={handleChange} />
        <Box sx={{ textAlign: "center" }}>
          <Button>Submit</Button>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default ContactForm
