import { graphql } from "gatsby"
import AboutPage from "../components/about-page"

export default AboutPage

export const query = graphql`
  query($formatString: String!) {
    allBook {
      nodes {
        slug
        title
        subtitle
        overview
        date(formatString: $formatString)
        cover {
          childImageSharp {
            fluid(maxWidth: 256) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
