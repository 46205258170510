/** @jsx jsx */
import { Heading, jsx, Text } from "theme-ui"
import BookPreview from "./book-preview"
import ContactForm from "./contact-form"
import Layout from "./layout"
import SEO from "./seo"

const AboutPageComponent = ({ books }) => (
  <Layout>
    <SEO title="About Me" />
    <Heading variant="styles.h2" mb={4}>
      About Me
    </Heading>
    <Text variant="styles.p">Hey there!</Text>
    <br />
    <Text variant="styles.p">
      I am Venelin and I am thrilled to invite you on a journey through the
      amazing world of Machine Learning.
    </Text>
    <br />
    <Text variant="styles.p">
      I’ve been working on quite a lot of software projects in the past 10+
      years, started my PhD program in Bayesian Statistics and been fascinated
      by Machine Learning in the past 5+ years (including industry experience).
      Hopefully, some of my learning might be useful for you too!
    </Text>

    <ContactForm />

    <section
      sx={{
        mt: [3, 3, 5],
      }}
    >
      {books.map(b => (
        <BookPreview key={b.slug} book={b} />
      ))}
    </section>
  </Layout>
)

const AboutPage = ({ data }) => {
  const { allBook } = data

  return <AboutPageComponent books={allBook.nodes} />
}

export default AboutPage
